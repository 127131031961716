import { createContext, useContext, useEffect } from 'react';
import useLocation from 'wouter/use-location';
import { useMFModal } from '@medifind/popups';
import { Button } from '@medifind/shared-basic-components';
import { useAppVersion } from '@medifind/zustand';
export const lockContext = createContext({
  lock: false,
  linkLock: false,
  message: '',
  onContinue: null,
  onCancel: null,
  onClose: null,
  continueOnCancel: false,
  manual: {},
  classes: {},
  style: {},
});
export const LocationConfirmationDialog = () => {
  const ctx = useContext(lockContext);
  const { MFModal, openModal, closeModal } = useMFModal();
  useEffect(() => {
    ctx.openModal = openModal;
  }, []);
  const classes = ctx.classes;
  const style = ctx.style;
  return (
    <MFModal
      title={ctx.manual.title ?? ctx.title ?? 'You are leaving the page'}
      onClose={() => (ctx.manual = {})}
      classes={classes}
      style={style}
    >
      <div className={classes.container} style={style.container}>
        <div className={classes.body} style={style.body}>
          {ctx.manual.message ?? ctx.message ?? 'Are you sure you want to leave this page?'}
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'space-around', marginTop: '1rem', ...style.buttonContainer }}
          className={classes.buttonContainer}
        >
          <Button
            className={classes.cancelButton}
            style={style.cancelButton}
            label={ctx.manual.cancelButtonText ?? ctx.cancelButtonText ?? 'Cancel'}
            color="inverted-blue"
            onClick={async () => {
              let keepGoing;
              if (ctx.manual.onCancel ?? ctx.onCancel) keepGoing = await (ctx.manual.onCancel ?? ctx.onCancel)();
              if (keepGoing !== false) {
                if (ctx.manual.continueOnCancel ?? ctx.continueOnCancel) (ctx.manual.onClose ?? ctx.onClose)();
              }
              ctx.manual = {};
              closeModal();
            }}
          />
          <Button
            className={classes.continueButton}
            style={style.continueButton}
            label={ctx.manual.continueButtonText ?? ctx.continueButtonText ?? 'Continue'}
            color="red"
            onClick={async () => {
              let keepGoing;
              if (ctx.manual.onContinue ?? ctx.onContinue) keepGoing = await ctx.onContinue();
              if (keepGoing !== false) {
                (ctx.manual.onClose ?? ctx.onClose)();
              }
              ctx.manual = {};
              closeModal();
            }}
          />
        </div>
      </div>
    </MFModal>
  );
};
export const useLocationWithConfirmation = (args) => {
  const [location, setLocation] = useLocation();
  const ctx = useContext(lockContext);
  const { appUpdated } = useAppVersion();

  return [
    location,
    (newLocation, options) => {
      const setLocationOrReload = (newLocation, options) => {
        if (appUpdated) {
          window.location = newLocation;
        } else {
          setLocation(newLocation, options);
        }
      };

      if (ctx.lock) {
        ctx.onClose = () => setLocationOrReload(newLocation, options);
        if (ctx.openModal) ctx.openModal();
      } else {
        setLocationOrReload(newLocation, options);
      }
    },
  ];
};

export const useLinkWithConfirmation = (args) => {
  const ctx = useContext(lockContext);
  const [, setLocation] = useLocation();

  return {
    linkProps: (props) => {
      const url = props.to || props.href;
      function openInNewTab(href) {
        Object.assign(document.createElement('a'), {
          target: props.target,
          rel: props.rel,
          href: href,
          onClick: props.onClick,
        }).click();
      }
      const gotoLocation = (newLocation) => {
        if (url && props.target === '_blank') {
          openInNewTab(url);
        } else if (url) {
          setLocation(newLocation);
        } else {
          if (props.onClick) props.onClick(event);
        }
      };
      const hook = (event) => {
        if (ctx.linkLock && (url.includes('https://') || url.includes('http://'))) {
          event.preventDefault();
          ctx.onClose = () => gotoLocation(props.to || props.href);
          if (ctx.openModal) ctx.openModal();
        } else {
          if (props.onClick) props.onClick(event);
        }
      };
      return { ...props, onClick: hook };
    },
  };
};

export const useBackWithConfirmation = (args) => {
  const ctx = useContext(lockContext);
  return () => {
    if (ctx.lock) {
      ctx.onClose = () => window.history.back();
      if (ctx.openModal) ctx.openModal();
    } else {
      window.history.back();
    }
  };
};
export const useManualConfirmation = () => {
  const ctx = useContext(lockContext);

  return ({
    title, // Dialog title
    message, // Dialog message
    continueButtonText, // The text for the continue button
    cancelButtonText, // The text for the cancel button
    onContinue, // Called on cancel click, is async friendly
    onCancel, // Called on cancel click, is async friendly
  } = {}) => {
    ctx.lock = true;
    ctx.manual = { title, message, continueButtonText, cancelButtonText, onContinue, onCancel, onClose: () => null };
    ctx.manual.message = message;
    ctx.manual.continueButtonText = continueButtonText;
    ctx.manual.cancelButtonText = cancelButtonText;
    ctx.manual.onContinue = onContinue;
    ctx.manual.onCancel = onCancel;

    if (ctx.openModal) ctx.openModal();
  };
};
