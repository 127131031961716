import { forwardRef } from 'react';
import classNames from 'classnames';
import { Link } from 'wouter';
import { useLazyRoutePreload } from '@medifind/hooks';
import { useLinkWithConfirmation } from '@medifind/router';
import { Skeleton } from '@medifind/shared-basic-components';
import { checkForUndefined } from '@medifind/utils';

const MFLink = forwardRef(
  (
    {
      target,
      children,
      fallback,
      external,
      clickOverride,
      noTabIndex,
      rel,
      routes,
      disabled,
      style = {},
      className,
      analyticsLinkType,
      ...props
    },
    ref,
  ) => {
    const url = props.to || props.href;
    const { linkProps } = useLinkWithConfirmation();
    checkForUndefined(url);
    checkForUndefined(external);
    const hasValidProtocol = url && (url.indexOf('mailto:') === 0 || url.indexOf('tel:') === 0 || url.indexOf('#') === 0);
    useLazyRoutePreload({ paths: [url], routes }, 10000);
    if (!url && fallback) {
      return <Skeleton {...fallback} />;
    } else if (disabled) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <span {...(noTabIndex ? { tabIndex: '-1' } : {})} style={style} className={className} {...props} onClick={null}>
          {children}
        </span>
      );
    } else if (url && (url.indexOf(process.env.NX_LOCAL_HOST) === 0 || url.indexOf('/') !== 0 || hasValidProtocol || target === '_blank')) {
      return (
        <a
          {...linkProps({
            ...(analyticsLinkType && { 'data-link-type': analyticsLinkType }),
            ...(noTabIndex ? { tabIndex: '-1' } : {}),
            onClick: clickOverride,
            style: style,
            className: className,
            ...props,
            href: url.includes('://') || url.indexOf('/') === 0 || hasValidProtocol ? url : `https://${url}`,
            target: target,
            rel: target === '_blank' ? Array.from(new Set([...(rel?.split(' ') || []), 'noopener'])).join(' ') : rel,
          })}
        >
          {children}
        </a>
      );
    } else if (url) {
      return (
        <Link
          {...linkProps({
            rel: rel,
            target: target,
            ...(noTabIndex ? { tabIndex: '-1' } : {}),
            onClick: clickOverride,
            className: className,
            style: style,
            ...(analyticsLinkType && { 'data-link-type': analyticsLinkType }),
            ...props,
          })}
        >
          {!children ? <>&zwnj;</> : Array.isArray(children) ? children : [children]}
        </Link>
      );
    } else {
      return (
        <span
          {...linkProps({
            ref: ref,
            id: props.id,
            ...(props.onClick
              ? {
                  onClick: props.onClick,
                  role: 'button',
                  tabIndex: noTabIndex ? '-1' : '0',
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') props.onClick();
                  },
                }
              : {}),
            className: classNames(className, 'mf-link'),
            style: { ...style, ...(props.onClick ? { cursor: 'pointer' } : {}) },
          })}
        >
          {children}
        </span>
      );
    }
  },
);
const MFNavLink = forwardRef(({ children, activeClassName, className, matchRoute, alts, isActive, ...props }, ref) => {
  return (
    <MFLink {...props} className={classNames(className, { [activeClassName]: isActive })} ref={ref}>
      {children}
    </MFLink>
  );
});
MFNavLink.displayName = 'MFNavLink';
MFLink.displayName = 'MFLink';
export { MFLink as default, MFLink, MFNavLink };
