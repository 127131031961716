import { useEffect } from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const storeName = 'app-version';
export const useAppVersion = create(
  devtools(
    (set) => ({
      appVersion: document.querySelector('meta[name="version"]')?.content,
      appUpdated: false,
    }),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const useAppVersionPoll = ({ pollInterval = 30000, disable = false }) => {
  const next = () => setTimeout(getVersion, pollInterval);
  const { appVersion } = useAppVersion((state) => state);

  const getVersion = () =>
    window
      .fetch('/version', {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then(async (res) => {
        if (!res.ok) {
          next();
          return;
        }
        const newVersion = (await res.text())?.trim();
        if (appVersion != null && appVersion !== newVersion) {
          useAppVersion.setState({ appVersion: newVersion, appUpdated: true }, true);
        }
        next();
      })
      .catch(() => next());

  useEffect(() => {
    if (!disable) next();
  }, [disable]);

  // return useAppVersion();
};
